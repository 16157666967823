<template>
  <div id="invitationsTab">
    <div v-if="invitations.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="invitation in invitations" :key="invitation.invitationId" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`invitation-${invitation.invitationId}`" variant="light" class="text-left">
              <b-icon-file-earmark-text/> {{ $t('comp.invitationstab.invitationForProject.label', {
              invitation: invitation.invitationId,
              project: invitation.forProject.label
            }) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`invitation-${invitation.invitationId}`" accordion="invitations" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <Invitation :invitation="invitation" :view="view"/>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.invitationstab.noInvitation.description') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Invitation from '@/components/user/Invitation'

export default {
  name: 'InvitationsTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Invitation
  },
  props: {
    invitations: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      view: 'user'
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
