<template>
  <div id="voucherRedeemTab">
    <b-form inline>
      <b-form-input v-model="voucherCode" class="mb-2 mr-sm-2 mb-sm-0" maxlength="10" size="sm"/>
      <b-button variant="primary" @click="redeemVoucher" class="mb-2 mr-sm-2 mb-sm-0" size="sm">
        <b-icon-plus-square/> {{ $t('comp.voucherredeemtab.redeembutton.label') }}
      </b-button>
      <b-button v-if="!voucherStart" variant="secondary" @click="resetAll" class="mb-2 mr-sm-2 mb-sm-0" size="sm">
        <b-icon-arrow-repeat/> {{ $t('comp.voucherredeemtab.resetbutton.label') }}
      </b-button>
    </b-form>
    <b-alert v-if="voucherStart && !voucherError" variant="info" show class="my-3 mr-sm-2 mb-sm-0">
      <b-icon-info-circle/> {{ $t('comp.voucherredeemtab.voucherredeem.description') }}
    </b-alert>
    <b-alert v-if="!voucherStart && !voucherError" variant="success" show class="my-3 mr-sm-2 mb-sm-0">
      <b-icon-check-circle/> {{ $t('comp.voucherredeemtab.vouchersuccess.description') }}
    </b-alert>
    <b-alert v-if="!voucherStart && voucherError" variant="danger" show class="my-3 mr-sm-2 mb-sm-0">
      <b-icon-exclamation-circle/> {{ $t('comp.voucherredeemtab.vouchererror.description') }}
    </b-alert>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'VoucherRedeemTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
  },
  data () {
    return {
      view: 'user',
      voucherStart: true,
      voucherError: false,
      voucherCode: ''
    }
  },
  computed: {
  },
  methods: {
    redeemVoucher () {
      this.reset()
      if (this.voucherCode !== '') {
        this.$store.dispatch({ type: 'user/redeemVoucher', voucherCode: this.voucherCode }).then(
          response => {
            this.makeToast(
              this.$i18n.t('redeem.text', { id: this.voucherCode }),
              this.$i18n.t('redeem.title'),
              'success'
            )
            this.voucherStart = false
            this.voucherError = false
          }
        ).catch(
          error => {
            this.makeToast(
              this.$i18n.t('error.text', { id: this.voucherCode, status: error.status, message: 'Internal Server Error: Voucher could not be redeemed!' }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
            this.voucherStart = false
            this.voucherError = true
          }
        )
      }
    },
    reset () {
      this.voucherStart = true
      this.voucherError = false
    },
    resetAll () {
      this.voucherStart = true
      this.voucherError = false
      this.voucherCode = ''
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
