<template>
  <div id="accounts">
    <b-button @click="getAccounts()" variant="primary" class="mb-3" size="sm">
      <b-icon-arrow-repeat/> {{ $t('comp.accounts.refreshButton.label') }}
    </b-button>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.accounts.active.label') + ` (${accountsActive.length})`" active>
        <AccountsTab :accounts="accountsActive"/>
      </b-tab>
      <b-tab :title="$t('comp.accounts.archived.label') + ` (${accountsArchived.length})`">
        <AccountsTab :accounts="accountsArchived"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import AccountsTab from '@/components/user/AccountsTab'

export default {
  name: 'Accounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    AccountsTab
  },
  data () {
    return {
      accounts: []
    }
  },
  computed: {
    accountsActive () {
      return this.$store.getters['user/accountsActive']
    },
    accountsArchived () {
      return this.$store.getters['user/accountsArchived']
    }
  },
  created () {
    this.getAccounts()
  },
  methods: {
    getAccounts () {
      this.$store.dispatch({ type: 'user/getAccounts' })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
