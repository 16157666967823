<template>
  <div id="requestsTab">
    <div v-if="requests.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="request in requests" :key="request.requestId" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`request-${request.requestId}`" variant="light" class="text-left">
              <b-icon-file-earmark-text/> {{ $t('comp.requeststab.requestForProject.label', {
              request: request.requestId,
              project: request.forProject.label
            }) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`request-${request.requestId}`" accordion="requests" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-button v-if="isPending || isPendingWithFollowUp" @click="withdrawRequest(request)"
                          v-b-modal="`request-modal-${request.requestId}`" variant="danger" class="ml-3">
                  <b-icon-trash/> {{ $t('comp.requeststab.withdraw.button.label') }}
                </b-button>
                <b-button v-if="isPendingWithFollowUp" @click="answerFollowUp(request)"
                          v-b-modal="`request-modal-${request.requestId}`" variant="warning" class="ml-3">
                  <b-icon-question/> {{ $t('comp.requeststab.answerFollowUp.button.label') }}
                </b-button>
              </b-card-text>
              <b-card-text>
                <RequestInfo :request="request" view="user"/>
              </b-card-text>
            </b-card-body>
          </b-collapse>
          <b-modal :id="`request-modal-${request.requestId}`" :title="modalHeader" size="xl" @hidden="resetForms">
            <div>
              <b-form v-if="requestForm.showForm">
                <RequestForm :request="requestForm" :action="actionRequest" :view="view"/>
              </b-form>
            </div>
            <template #modal-footer="{ cancel }">
              <b-button variant="dark" @click="cancel()">
                {{ $t('cancel.label') }}
              </b-button>
              <b-button @click="onSubmit" variant="primary">
                <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
              </b-button>
            </template>
          </b-modal>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">{{ $t('comp.requeststab.noRequest.description') }}</b-alert>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { requestServiceForUserView } from '@/services/request.service'
import RequestInfo from '@/components/generic/info/RequestInfo'
import RequestForm from '@/components/generic/form/RequestForm'

export default {
  name: 'RequestsTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    RequestInfo,
    RequestForm
  },
  props: {
    requests: {
      type: Array,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    isPending: {
      type: Boolean,
      default: false
    },
    isPendingWithFollowUp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      actionRequest: '',
      requestForm: {
        form: null,
        showForm: false
      }
    }
  },
  computed: {
    modalHeader () {
      if (this.actionRequest === 'withdraw') {
        return this.$i18n.t('comp.requeststab.withdraw.header',
          { request: this.requestForm.form.requestId })
      } else if (this.actionRequest === 'answerFollowUp') {
        return this.$i18n.t('comp.requeststab.answerFollowUpRequest.header',
          { request: this.requestForm.form.requestId })
      } else {
        return ''
      }
    }
  },
  methods: {
    resetForms () {
      this.actionRequest = ''
      this.requestForm.form = null
      this.requestForm.showForm = false
    },
    withdrawRequest (request) {
      this.actionRequest = 'withdraw'
      this.requestForm.form = _.cloneDeep(request)
      this.requestForm.form.state = 'withdrawn'
      this.requestForm.showForm = true
    },
    answerFollowUp (request) {
      this.actionRequest = 'answerFollowUp'
      this.requestForm.form = _.cloneDeep(request)
      this.requestForm.form.state = 'pending'
      this.requestForm.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.actionRequest === 'withdraw') {
        requestServiceForUserView.update(this.requestForm.form).then(
          (response) => {
            this.makeToast(
              this.$i18n.t('comp.requeststab.withdrawnSuccessfully.label',
                { request: this.requestForm.form.requestId }),
              this.$i18n.t('comp.requeststab.withdrawnSuccessfully.title'),
              'success')
            this.$bvModal.hide(`request-modal-${this.requestForm.form.requestId}`)
            this.$emit('withdraw-request', response)
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
            this.$i18n.t('result.error.title'),
            'danger')
        )
      } else if (this.actionRequest === 'answerFollowUp') {
        requestServiceForUserView.update(this.requestForm.form).then(
          (response) => {
            this.makeToast(
              this.$i18n.t('comp.requeststab.answeredFollowUpSuccessfully.label',
                { request: this.requestForm.form.requestId }),
              this.$i18n.t('comp.requeststab.answeredFollowUpSuccessfully.title'),
              'success')
            this.$bvModal.hide(`request-modal-${this.requestForm.form.requestId}`)
            this.$emit('answer-follow-up', response)
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
            this.$i18n.t('result.error.title'),
            'danger')
        )
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
