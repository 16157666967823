<template>
  <div id="voucher">
    <VoucherInfo :voucher="voucher" :view="view"/>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import VoucherInfo from '@/components/user/VoucherInfo'

export default {
  name: 'Voucher',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    VoucherInfo
  },
  props: {
    voucher: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  beforeMount () {
  }
}
</script>

<style scoped>
</style>
