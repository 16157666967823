<template>
  <div id="invitations">
    <b-button variant="primary" @click="getInvitations" class="mb-3" size="sm">
      <b-icon-arrow-repeat/> {{ $t('comp.invitations.refresh.label') }}
    </b-button>

    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.invitations.pending.label') + ` (${invitationsPending.length})`" active>
        <InvitationsTab :invitations="invitationsPending"/>
      </b-tab>
      <b-tab :title="$t('comp.invitations.archived.label') + ` (${invitationsArchived.length})`">
        <InvitationsTab :invitations="invitationsArchived"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import InvitationsTab from '@/components/user/InvitationsTab'

export default {
  name: 'Invitations',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    InvitationsTab
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    invitationsPending () {
      return this.$store.getters['user/invitationsPending']
    },
    invitationsArchived () {
      return this.$store.getters['user/invitationsArchived']
    }
  },
  created () {
    this.getInvitations()
  },
  methods: {
    getInvitations () {
      this.$store.dispatch({ type: 'user/getInvitations' })
    }
  }
}
</script>

<style scoped>
</style>
