<template>
  <div id="account">
    <b-card-text>
      <b-card class="mb-3">
        <template #header>
          <b-icon-info-circle class="mr-1"/> {{ $t('comp.account.details.label') }}
        </template>
        <b-card-text>
          <AccountInfo :account="account" :view="view"/>
        </b-card-text>
      </b-card>
      <b-card class="mb-3">
        <template #header>
          <b-iconstack v-if="!account.publicKeysFound && account.state === 'active'" class="mr-1" shift-v="1" scale="1.2">
            <b-icon stacked icon="circle-fill" variant="danger"/>
            <b-icon stacked icon="key-fill" scale="0.75" variant="light"/>
          </b-iconstack>
          <b-icon-key-fill v-else class="mr-1"/>
          {{ $t('comp.account.publicKeys.title') }}
        </template>
        <b-card-text>
          <PublicKeys :account="account" view="user"/>
        </b-card-text>
      </b-card>
      <b-card class="mb-3">
        <template #header>
          <div v-if="account?.prefix"><b-icon-wrench class="mr-1"/> {{ $t('comp.account.tools.fau.title') }} </div>
          <div v-else><b-icon-wrench class="mr-1"/> {{ $t('comp.account.tools.nhr.title') }} </div>
        </template>
        <b-form class="mx-0 mx-md-3 my-1" method="post" :action="ccaction" target="_blank">
          <b-button type="submit" variant="primary" class="w-100" :title="$t('comp.account.ccgoto.title')">
              <b-icon-speedometer class="mr-2"/>{{ $t('comp.account.ccgoto.label') }}
          </b-button>
        </b-form>
        <div v-if="account.isExpired" class="mx-0 mx-md-3 my-1">
          <b-button variant="primary" class="w-100" :title="$t('comp.account.jhgoto.disabled.title')" disabled>
            <b-icon-window class="mr-2"/>{{ $t('comp.account.jhgoto.disabled.label') }}
          </b-button>
        </div>
        <b-form v-else class="mx-0 mx-md-3 my-1" method="post" :action="jhaction" target="_blank">
          <b-button type="submit" variant="primary" class="w-100" :title="$t('comp.account.jhgoto.title')">
            <b-icon-window class="mr-2"/>{{ $t('comp.account.jhgoto.label') }}
          </b-button>
        </b-form>
      </b-card>
      <!-- Card for AccountUsageInfo: Only shown if data (internal) -->
      <AccountUsageInfo :account="account.username" :view="view"/>
    </b-card-text>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import AccountInfo from '@/components/generic/info/AccountInfo'
import PublicKeys from '@/components/generic/PublicKeys'
import AccountUsageInfo from '@/components/generic/info/AccountUsageInfo'

export default {
  name: 'Account',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    PublicKeys,
    AccountInfo,
    AccountUsageInfo
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ccaction: this.$store.getters['user/getActionForCC'](this.account.username),
      jhaction: this.$store.getters['user/getActionForJH'](this.account.username),
      usages: []
    }
  },
  created () {
    if (this.ccaction === '') { this.buildActionForCC() }
    if (this.jhaction === '' && !this.account.isExpired) { this.buildActionForJH() }
  },
  methods: {
    buildActionForCC () {
      return this.$store.dispatch({ type: 'user/buildActionForCC', account: this.account.username }).then(
        response => {
          this.ccaction = response
        }
      ).catch(
        error => {
          console.log('Error while requesting CC token: ' + error)
        }
      )
    },
    buildActionForJH () {
      // If Account has prefix: Build token for tier3 endpoint, else: buiild for nhr endpoint
      return this.$store.dispatch({ type: 'user/buildActionForJH', account: this.account.username, tokenTarget: this.account?.prefix ? 'fau' : 'nhr' }).then(
        response => {
          this.jhaction = response
        }
      ).catch(
        error => {
          console.log('Error while requesting JH token: ' + error)
        }
      )
    }
  }
}
</script>

<style scoped>
</style>
