<template>
  <div class="user" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("comp.user.title") }}</h1>
    <b-card-group v-if="hasRoleUser" deck class="m-2">
      <b-card class="mt-3">
        <template #header>
          <b-icon-person-circle/> {{ $t('comp.user.accounts.label')}}
          <span>{{ `(${user.givenName} ${user.surname})` }}</span>
        </template>
        <Accounts/>
      </b-card>
      <b-card v-if="false" class="mt-3"> <!-- don't show requests for the moment -->
        <template #header>
          <b-icon-file-earmark-text/> {{ $t('comp.user.requests.label') }}
        </template>
        <Requests/>
      </b-card>
      <b-card class="mt-3">
        <template #header>
          <b-icon-file-earmark-text/> {{ $t('comp.user.invitations.label', {mail: user.email}) }}
          <span>({{ $t('comp.user.invitations.info.text') }})</span>
          <b-iconstack v-if="!showVouchers" class="float-right" font-scale="1.5" @click="openVouchers()">
            <b-icon stacked icon="circle"></b-icon>
            <b-icon stacked scale="0.8" icon="tags"></b-icon>
          </b-iconstack>
        </template>
        <Invitations/>
      </b-card>
      <b-card class="mt-3" v-if="showVouchers">
        <template #header>
          <b-icon-file-earmark-text/> {{ $t('comp.user.vouchers.label') }}
          <b-iconstack class="float-right" font-scale="1.5" @click="closeVouchers()">
            <b-icon stacked icon="circle"></b-icon>
            <b-icon stacked scale="0.8" icon="tags-fill"></b-icon>
          </b-iconstack>
        </template>
        <Vouchers/>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import hasRoleMixin from '@/mixins/hasRole.mixin'
import Accounts from '@/components/user/Accounts'
import Requests from '@/components/user/Requests'
import Invitations from '@/components/user/Invitations'
import Vouchers from '@/components/user/Vouchers'
import { userServiceForUserView } from '@/services/user.service'

export default {
  name: 'User',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, hasRoleMixin],
  components: {
    Invitations,
    Accounts,
    Requests,
    Vouchers
  },
  data () {
    return {
      user: {},
      showVouchers: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('user.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  },
  watch: {
    currentUser: function (newUser, oldUser) {
      this.getUser()
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    getUser () {
      if (this.currentUser) {
        userServiceForUserView.get(this.currentUser.username).then((user) => {
          this.user = user
        }).catch(error => {
          console.log(error)
        })
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    openVouchers () {
      this.showVouchers = true
    },
    closeVouchers () {
      this.showVouchers = false
    }
  }
}
</script>
<style scoped>
/* https://stackoverflow.com/questions/36487389/bootstrap-4-card-deck-table-cell-columns-responsive-based-on-viewport */
  .card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(576px, 1fr));
    grid-gap: 0rem;
  }
</style>
