<template>
  <div id="vouchers">
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.vouchers.redeem.label')" active>
        <VoucherRedeemTab/>
      </b-tab>
      <b-tab :title="$t('comp.vouchers.archived.label') + ` (${vouchersArchived.length})`">
        <VouchersTab :vouchers="vouchersArchived"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import VouchersTab from '@/components/user/VouchersTab'
import VoucherRedeemTab from '@/components/user/VoucherRedeemTab'

export default {
  name: 'Vouchers',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    VouchersTab,
    VoucherRedeemTab
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    vouchersArchived () {
      return this.$store.getters['user/vouchersArchived']
    }
  }
}
</script>

<style scoped>
</style>
