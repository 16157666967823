<template>
  <div id="invitation">
    <b-button v-if="isStatePending" variant="success" @click="acceptInvitation" class="mb-3 ml-3">
      <b-icon-check/> {{ $t('comp.invitation.accept.label') }}
    </b-button>
    <b-button v-if="isStatePending" variant="danger" @click="declineInvitation" class="mb-3 ml-3">
      <b-icon-x/> {{ $t('comp.invitation.decline.label') }}
    </b-button>

    <InvitationInfo :invitation="invitation" :view="view"/>

    <b-modal :id="`invitation-modal-${invitation.invitationId}`" :title="$t('comp.invitation.accepted.label', { project: invitation.forProject.key })" size="lg" ok-only ok-variant="success">
      <p>{{$t('comp.invitation.accepted.text', { project: invitation.forProject.label })}}</p>
      <ul>
        <li>{{$t('comp.invitation.accepted.info.account')}}</li>
        <li>{{$t('comp.invitation.accepted.info.publickey')}}</li>
        <li>{{$t('comp.invitation.accepted.info.delay')}}</li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import InvitationInfo from '@/components/user/InvitationInfo'

export default {
  name: 'Invitation',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    InvitationInfo
  },
  props: {
    invitation: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  beforeMount () {
  },
  computed: {
    isStatePending () {
      return this.invitation.state === 'pending'
    }
  },
  methods: {
    acceptInvitation () {
      this.invitation.state = 'accepted'
      return this.$store.dispatch({ type: 'user/updateInvitation', invitation: this.invitation }).then(
        response => {
          this.makeToast(
            this.$i18n.t('updated.text', { id: this.invitation.invitationId, code: response.code }),
            this.$i18n.t('comp.invitation.acceptedInvitation.title'),
            'success'
          )
          this.$bvModal.show(`invitation-modal-${this.invitation.invitationId}`)
        }
      ).catch(
        error => {
          if (error.status === 409 && error.request.response === 'invitationNotPending') {
            // User accepts already accepted invite, due to browser tabs not being synchronized
            // Only log to not confuse users: Frontend reloads accounts in dispatch and invites in this case as if nothing is wrong
            console.log(this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.notpending.error'), id: this.invitation.forProject.key }))
          } else if (error.status === 409 && error.request.response === 'tier3AccountExists') {
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.tier3exists.error'), id: this.invitation.forProject.key }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          } else {
            const parsed = JSON.parse(error.request.response)
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: parsed.error }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        }
      )
    },
    declineInvitation () {
      this.invitation.state = 'declined'
      this.$store.dispatch({ type: 'user/updateInvitation', invitation: this.invitation }).then(
        response => {
          this.makeToast(
            this.$i18n.t('updated.text', { id: this.invitation.invitationId, code: response.code }),
            this.$i18n.t('comp.invitation.declinedInvitation.title'),
            'success'
          )
        }
      ).catch(
        error => {
          if (error.status === 409) {
            // Only log to not confuse users: Frontend silently reloads accounts and invites in this case as if nothing is wrong
            console.log(this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.notpending.error'), id: error.request.response }))
          } else {
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        }
      )
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
