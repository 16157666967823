<template>
  <div id="voucherInfo">
    <b-card class="mb-3">
      <template #header>
        <b-icon-info-circle/> {{ $t('comp.voucherinfo.details.label') }}
      </template>
      <ul>
        <li><strong>{{ $t('comp.voucherinfo.forProject.label') }}:</strong> {{ voucher.forProject.label }}</li>
        <li v-if="voucher.account"><strong>{{ $t('comp.voucherinfo.account.label') }}:</strong> {{ voucher.account }}</li>
        <li v-if="voucher.usedAt"><strong>{{ $t('comp.voucherinfo.usedAt.label') }}:</strong> {{ formatDate(voucher.usedAt) }}</li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'VoucherInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    voucher: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
</style>
