<template>
  <div id="requests">
    <b-button @click="addRequest" v-b-modal.requestNew variant="success" class="mb-3">
      <b-icon-plus/> {{ $t('comp.requests.addRequest.label') }}
    </b-button>
    <b-button @click="loadRequests()" variant="primary" class="ml-3 mb-3">
      <b-icon-arrow-repeat/> {{ $t('comp.requests.refreshButton.label') }}
    </b-button>
    <b-modal id="requestNew" :title="$t('comp.requests.addRequest.label')" size="xl" @hidden="resetForms">
      <div>
        <b-form v-if="requestForm.showForm">
          <RequestForm :request="requestForm" action="add" view="user"/>
        </b-form>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">
          {{ $t('cancel.label') }}
        </b-button>
        <b-button @click="onSubmit" variant="primary">
          <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
        </b-button>
      </template>
    </b-modal>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.requests.pending.label') + ` (${requestsPending.length})`" active>
        <RequestsTab :requests="requestsPending" :is-pending="true" @withdraw-request="withdrawRequest" view="user"/>
      </b-tab>
      <b-tab :title="$t('comp.requests.pendingWithFollowUp.label') + ` (${requestsPendingWithFollowUp.length})`">
        <RequestsTab :requests="requestsPendingWithFollowUp" :is-pending-with-follow-up="true"
                     @withdraw-request="withdrawRequest" @answer-follow-up="answerFollowUp" view="user"/>
      </b-tab>
      <b-tab :title="$t('comp.requests.archived.label') + ` (${requestsArchived.length})`">
        <RequestsTab :requests="requestsArchived" view="user"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { requestServiceForUserView } from '@/services/request.service'
import RequestsTab from '@/components/user/RequestsTab'
import RequestForm from '@/components/generic/form/RequestForm'

export default {
  name: 'Requests',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    RequestsTab,
    RequestForm
  },
  data () {
    return {
      requests: [],
      requestForm: {
        form: null,
        formReset: null,
        showForm: false,
        emptyForm: {
          forProject: null,
          message: null
        }
      }
    }
  },
  computed: {
    requestsPending () {
      return this.requests.filter(request => request.state === 'pending')
    },
    requestsPendingWithFollowUp () {
      return this.requests.filter(request => request.state === 'pending-follow-up')
    },
    requestsArchived () {
      return this.requests.filter(request => request.state !== 'pending' && request.state !== 'pending-follow-up')
    }
  },
  beforeMount () {
    this.loadRequests()
  },
  methods: {
    loadRequests () {
      requestServiceForUserView.list().then((requests) => {
        this.requests = requests.items
      }).catch(error => {
        console.error(error)
      })
    },
    withdrawRequest (requestWithdrawn) {
      const requestIndex = this.requests.findIndex(request => request.requestId === requestWithdrawn.requestId)
      Vue.set(this.requests, requestIndex, requestWithdrawn)
    },
    answerFollowUp (requestWithAnsweredFollowUp) {
      const requestIndex = this.requests.findIndex(request => request.requestId === requestWithAnsweredFollowUp.requestId)
      Vue.set(this.requests, requestIndex, requestWithAnsweredFollowUp)
    },
    addRequest () {
      this.requestForm.form = _.cloneDeep(this.requestForm.emptyForm)
      this.requestForm.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      requestServiceForUserView.create(this.requestForm.form).then(
        response => {
          this.makeToast(
            this.$i18n.t('comp.requests.createdSuccessfully.label',
              { request: response.requestId, code: response.code }),
            this.$i18n.t('comp.requests.createdSuccessfully.title'),
            'success'
          )
          this.requests.push(response)
          this.$bvModal.hide('requestNew')
        }
      ).catch(
        error => this.makeToast(
          this.$i18n.t('error.text',
            { status: error.status, message: error.message, id: this.requestForm.form.forProject.label }),
          this.$i18n.t('result.error.title'),
          'danger'
        )
      )
    },
    resetForms () {
      this.requestForm.form = null
      this.requestForm.showForm = false
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
